import React from "react";
import { Links1, Links2, Links3 } from "../Navigationlinks";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./NavbarCS.css";
import "./Mobilenav.css";
import Mobilenav from "./Mobilenav";
import { useState, useEffect, useRef } from "react";
import styles from "./Navbar.module.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import Modal from '../Model/Modal';
import LoginSignup from "../LoginSignup/LoginSignup";
import Signup from "../SignupComponent/Signup";
import { useDispatch } from "react-redux";
import { showAlertMessage } from "../MasterComponents/CommonFunctionalities";
import { logout } from "../../store/actions/userActions"
import Loader from "../UI Helpers/Simple Loading/Loader";
import CommonCalls from '../../components/CommonAPICalls/CommonCalls';

const Navbar = ({ noLinearBackground, landingPage = false, noLinearBackgroundVideo }) => {
  const [menuState, setMenuState] = useState(false);
  const [isDropdownOpenDesk, setIsDropdownOpenDesk] = useState(false);
  const [OpenPortalDropdown, setOpenPortalDropdown] = useState(false);
  const [isNotesSubMenuOpen, setIsNotesSubMenuOpen] = useState(false);
  const [isDropdownOpenSubServices, setIsDropdownOpenSubServices] = useState(false);
  const [isDropdownOpenZakatServices, setIsDropdownOpenZakatServices] = useState(false);
  const [isDropdownOpenNafilServices, setIsDropdownOpenNafilServices] = useState(false);
  const [isDropdownOpenFitrahServices, setIsDropdownOpenFitrahServices] = useState(false);
  const [isDropdownOpenTheDesk, setIsDropdownOpenTheDesk] = useState(false);
  const [isDropdownOpenTheDeskTwo, setIsDropdownOpenTheDeskTwo] = useState(false);
  const [isDropdownOpenTheDeskThree, setIsDropdownOpenTheDeskThree] = useState(false);
  const [isDropdownOpenMore, setIsDropdownOpenMore] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [selected5, setSelected5] = useState(false);
  const [style, setStyle] = useState({ display: "none" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loginpressed, setLoginpressed] = useState(false);
  const [isDropdownOpenProfileDesk, setIsDropdownOpenProfileDesk] = useState(false);
  const [changeArrowcolor, setChangeArrowColor] = useState(false);
  const [serviceOption, setServices] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [loginPressed, setLoginPressed] = useState(true);
  const [isDropdownOpenSubRfWelfare, setIsDropdownOpenSubRfWelfare] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isDropdownOpenKhidmat, setIsDropdownOpenKhidmat] = useState(false);
  const [isDropdownOpenEducation, setIsDropdownOpenEducation] = useState(false);
  const [isDropdownOpenRfWelfare, setIsDropdownOpenRfWelfare] = useState(false);
  const [isDropdownOpenRfDisasterAid, setIsDropdownOpenRfDisasterAid] = useState(false);
  const [isDropdownOpenRfFood, setIsDropdownOpenRfFood] = useState(false);
  const [isDropdownOpenRfEducation, setIsDropdownOpenRfEducation] = useState(false);
  const [isDropdownOpenRfHealth, setIsDropdownOpenRfHealth] = useState(false);
  const [isDropdownOpenBusiness, setIsDropdownOpenBusiness] = useState(false);
  const [isDropdownOpenRfEhsaas, setIsDropdownOpenRfEhsaas] = useState(false);

  const userLoginStatus = JSON.parse(localStorage.getItem("userInfo"));
  const dispatch = useDispatch();

  const logoutHandler = () => {
    showAlertMessage("Logout Successfully ", "success")
    dispatch(logout());
    window.location.reload(false);
  };

  const apiCall = new CommonCalls();

  const getServices = async () => {
    setLoading(true)
    const response = await apiCall.getServicesItems();
    if (response.length > 0) {
      setError(false)
      setLoading(false)
      return response
    }
    else {
      setLoading(false)
      setError("Could not load data from server")
      return
    }
  }

  useEffect(() => {
    const getApiData = async () => {
      const servicesResponse = await getServices()
      if (!error) {
        setServices(servicesResponse)
      }
    }

    getApiData()
  }, []);


  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const navbarRef = useRef();
  const MainDropdownRef = useRef();
  const dropdownProfileRef = useRef();

  const handleScroll = () => {
    const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (currentScrollTop > lastScrollTop) {
      setIsNavbarVisible(false);
    } else {
      setIsNavbarVisible(true);
    }
    setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

  const handleMouseEnter = (item) => {
    setHoveredItem(item);
    if (item === 'notes') {
      setIsDropdownOpenDesk(true);
      setIsNotesSubMenuOpen(true);
    } else {
      setIsDropdownOpenDesk(false);
      setIsNotesSubMenuOpen(false);
    }
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
    setIsDropdownOpenDesk(false);
    setIsNotesSubMenuOpen(false);
  };

  const handleTheMouseEnter = (item) => {
    setHoveredItem(item);
    if (item === 'notes') {
      setIsDropdownOpenTheDesk(true);
      setIsNotesSubMenuOpen(true);
    } else {
      setIsDropdownOpenTheDesk(false);
      setIsNotesSubMenuOpen(false);
    }
  };

  const handleTheMouseLeave = () => {
    setHoveredItem(null);
    setIsDropdownOpenTheDesk(false);
    setIsNotesSubMenuOpen(false);
    setIsDropdownOpenTheDeskThree(false)
  };

  const handleTheMouseEnterTwo = (item) => {
    setHoveredItem(item);
    if (item === 'notes') {
      setIsDropdownOpenTheDeskTwo(true);
      setIsNotesSubMenuOpen(true);
    } else {
      setIsDropdownOpenTheDeskTwo(false);
      setIsNotesSubMenuOpen(false);
    }
  };
  const handleTheMouseEnterThree = (item) => {
    setHoveredItem(item);
    if (item === 'education') {
      setIsDropdownOpenTheDeskThree(true);
      setIsNotesSubMenuOpen(true);
    } else {
      setIsDropdownOpenTheDeskThree(false);
      setIsNotesSubMenuOpen(false);
    }
  };

  const handleTheMouseLeaveTwo = () => {
    setHoveredItem(null);
    setIsDropdownOpenTheDeskTwo(false);
    setIsNotesSubMenuOpen(false);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const navigate = useNavigate();
  const dropdownRef = useRef();
  const dropdownSubServices = useRef();
  const dropdownMore = useRef();

  const hamburgerdropdown = (state) => {
    setSelected5(!state);
  };

  const mouseEnterHandler = () => {
    setStyle({ display: "block" });
  };
  const mouseLeaveHandler = () => {
    setStyle({ display: "none" });
  };

  const handleMenuItemClick = () => {
    setIsDropdownOpenDesk(false);
    setIsNotesSubMenuOpen(false);
    setIsDropdownOpenZakatServices(false);
    setIsDropdownOpenSubServices(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isDropdownOpenDesk) {
        setIsDropdownOpenDesk(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpenDesk]);

  const menuClick = () => {
    setMenuState((prev) => !prev);
  };

  const openSignup = () => {
    setLoginPressed(false);
    openModal();
  };

  const openLogin = () => {
    setLoginPressed(true);
    openModal();
  };

  return (
    <>
      <>
        <header
          ref={navbarRef}
          style={{
            position: noLinearBackground && "absolute",
            zIndex: !noLinearBackground && 99,
            backgroundImage:
              noLinearBackgroundVideo || noLinearBackground
                ? "none"
                : "linear-gradient(to right, #1b1b1b , rgb(69, 69, 69))",
            transition: "top 0.3s",
            top: isNavbarVisible ? "22px" : "-100px",
          }}
          className={styles.desktopHeader}
        >
          <Container>
            <div className={styles.nav}>
              <h1 onMouseEnter={mouseLeaveHandler}>
                <Link to="/">
                  <img
                    src="/images/icons/Raza-Foundation-Logo.png"
                    className={styles.logo}
                    alt="moosa-khan-logo"
                  />
                </Link>
              </h1>
              <div className={!landingPage ? styles.navList : "d-none"}>
                <div className="navigation-dropdown">
                  <div
                    className="dropdown-trigger d-flex align-items-center"
                    onMouseEnter={() => handleMouseEnter("notes")}
                    onMouseLeave={handleMouseLeave}
                  >
                    <span className="nav-links font-play">Department</span>
                  </div>
                  <div
                    className={`dropdown-content ${
                      isDropdownOpenDesk ? "open" : "d-none"
                    }`}
                    onMouseEnter={() => handleMouseEnter("notes")}
                    onMouseLeave={handleMouseLeave}
                    style={{height:"450px",overflowY:"auto",width:"300px"}}
                  >
                    <div className="d-flex align-item-center gap-1 flex-column">
                      {/* <div className="navigation-dropdown">
        <div className="dropdown-trigger d-flex align-items-center"
          onMouseEnter={() => { setIsDropdownOpenZakatServices(true) }}
        >
          <span className="nav-links font-play" style={{ padding: "12px 16px"}}>Zakat </span>
        </div>
        <div className={`dropdown-content ${isDropdownOpenZakatServices ? 'open' : 'd-none'}`}
          onMouseEnter={() => { setIsDropdownOpenZakatServices(true) }}
          onMouseLeave={() => { setIsDropdownOpenZakatServices(false) }}
          style={{ left: "170px", top: "0px" }}
        >
          <div className="d-flex align-item-center gap-5">
            <div>
              {['medical', 'utilities', 'grocery', 'education-fees', 'wedding', 'business'].map((item) => (
                <Link
                  to={`/${item}`}
                  className="nav-links font-play dropdown-item text-start"
                  onClick={handleMenuItemClick}
                  onMouseEnter={() => setIsDropdownOpenZakatServices(true)}
                  key={item}
                >
                  <p className="itemsof-dropdown mb-0">{item.charAt(0).toUpperCase() + item.slice(1)}</p>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div> */}

                      {/* <div className="navigation-dropdown">
  <div className="dropdown-trigger d-flex align-items-center"
    onMouseEnter={() => {setIsDropdownOpenNafilServices(false); setIsDropdownOpenZakatServices(true);
      setIsDropdownOpenNafilServices(false);
      setIsDropdownOpenSubServices(false);
      setIsDropdownOpenFitrahServices(false)
      
    }}
  >
   <Link to="/zakat"><span className="nav-links font-play" style={{ padding: "12px 16px", textDecoration: "none", paddingLeft: "0px" }}
    onClick={handleMenuItemClick}>Zakat</span></Link> 
  </div>
  <div className={`dropdown-content ${isDropdownOpenZakatServices ? 'open' : 'd-none'}`}
    onMouseEnter={() => setIsDropdownOpenZakatServices(true)}
    onMouseLeave={() => setIsDropdownOpenZakatServices(false)}
    style={{ left: "120px", top: "0px" }}
  >
    <div className="d-flex align-items-center gap-5">
      <div>
        {['medical', 'utilities', 'grocery', 'education-fees', 'wedding', 'global-relief'].map((item) => (
          <Link
            to={`/${item}`}
            className="nav-links font-play dropdown-item text-start"
            onClick={handleMenuItemClick}
            onMouseEnter={() => setIsDropdownOpenZakatServices(true)}
            key={item}
          >
            <p className="itemsof-dropdown mb-0">{item.charAt(0).toUpperCase() + item.slice(1).replace('-', ' ')}</p>
          </Link>
        ))}
      </div>
    </div>
  </div>
</div> */}

                      {/* <div className="navigation-dropdown">
  <div className="dropdown-trigger d-flex align-items-center"
    onMouseEnter={() => {setIsDropdownOpenNafilServices(false); setIsDropdownOpenZakatServices(false); 
      setIsDropdownOpenSubServices(false);
      setIsDropdownOpenFitrahServices(true)
      
    }}
  >
    <Link to="/fitrah"><span className="nav-links font-play" style={{ padding: "12px 16px", textDecoration: "none", paddingLeft: "0px" }}
    onClick={handleMenuItemClick}>Fitrah</span></Link> 
  </div>
  <div className={`dropdown-content ${isDropdownOpenFitrahServices ? 'd-none' : 'd-none'}`}
    onMouseEnter={() => setIsDropdownOpenFitrahServices(true)}
    onMouseLeave={() => setIsDropdownOpenFitrahServices(false)}
    style={{ left: "120px", top: "0px" }}
  >
    <div className="d-flex align-items-center gap-5">
      <div>
        {['medical', 'utilities', 'grocery', 'education-fees', 'wedding', 'global-relief'].map((item) => (
          <Link
            to={`/${item}`}
            className="nav-links font-play dropdown-item text-start"
            onClick={handleMenuItemClick}
            onMouseEnter={() => setIsDropdownOpenFitrahServices(true)}
            key={item}
          >
            <p className="itemsof-dropdown mb-0">{item.charAt(0).toUpperCase() + item.slice(1).replace('-', ' ')}</p>
          </Link>
        ))}
      </div>
    </div>
  </div>
</div> */}

                      {/* <div className="navigation-dropdown">
  <div className="dropdown-trigger d-flex align-items-center"
    onMouseEnter={() => {setIsDropdownOpenNafilServices(true); setIsDropdownOpenZakatServices(false); 
      setIsDropdownOpenSubServices(false);
      setIsDropdownOpenFitrahServices(false)
      
    }}
  >
    <Link to="/nafil"><span className="nav-links font-play" style={{ padding: "12px 16px", textDecoration: "none", paddingLeft: "0px" }}
    onClick={handleMenuItemClick}>Nafil</span></Link> 
  </div>
  <div className={`dropdown-content ${isDropdownOpenNafilServices ? 'd-none' : 'd-none'}`}
    onMouseEnter={() => setIsDropdownOpenNafilServices(true)}
    onMouseLeave={() => setIsDropdownOpenNafilServices(false)}
    style={{ left: "170px", top: "0px" }}
  >
    <div className="d-flex align-items-center gap-5">
      <div>
        {['Sadaqa', 'Rashan', 'Medical', 'Education', 'Utilities', 'Global Relief', 'construction-of-mosque', 'Qurbani', 'Box', 'Mehfil', 'Sadat', 'trust-expenses', 'Others'].map((item) => (
          <Link
            to={`/${item}`}
            className="nav-links font-play dropdown-item text-start"
            onClick={handleMenuItemClick}
            onMouseEnter={() => setIsDropdownOpenNafilServices(true)}
            key={item}
          >
            <p className="itemsof-dropdown mb-0">{item.charAt(0).toUpperCase() + item.slice(1)}</p>
          </Link>
        ))}
      </div>
    </div>
  </div>
</div> */}
{serviceOption?
<>
  {
      serviceOption.map((service)=>{
        if(!service?.web_displayed)
        {
          return;
        }
        else if(service?.services_id == 7)
        {
          return(
            <div className="navigation-dropdown">
                        <div
                          className=""
                          onMouseEnter={() =>
                            handleTheMouseEnterThree("education")
                          }
                          onMouseLeave={handleTheMouseLeave}
                        >
                          <Link
                            to={service?.service_slug_url}
                            onClick={handleMenuItemClick}
                          >
                            <span
                              className="nav-links font-play"
                              style={{ padding: "0px 0px" }}
                            >
                              {service?.service_name}
                            </span>
                          </Link>
                        </div>
                        <div
                          className={`dropdown-content ${
                            isDropdownOpenTheDeskThree ? "open" : "d-none"
                          }`}
                          onMouseEnter={() =>
                            handleTheMouseEnterThree("education")
                          }
                          onMouseLeave={handleTheMouseLeave}
                          // style={{ left: "170px", top: "0px" }}
                          style={{ left: "0px", top: "37px" }}
                        >
                          <div className="d-flex align-item-center gap-1 flex-column">
                            <div className="navigation-dropdown">
                              <div
                                className="dropdown-trigger d-flex align-items-center"
                                onMouseEnter={() => {}}
                              >
                                <Link to="/services/school-fees">
                                  <span
                                    className="nav-links font-play"
                                    style={{
                                      padding: "12px 0px",
                                      textDecoration: "none",
                                      paddingLeft: "0px",
                                    }}
                                    onClick={handleMenuItemClick}
                                  >
                                    School Fees
                                  </span>
                                </Link>
                              </div>
                            </div>

                            <div className="navigation-dropdown">
                              <div
                                className="dropdown-trigger d-flex align-items-center"
                                onMouseEnter={() => {}}
                              >
                                <Link to="/services/dues">
                                  <span
                                    className="nav-links font-play"
                                    style={{
                                      padding: "12px 0px",
                                      textDecoration: "none",
                                      paddingLeft: "0px",
                                    }}
                                    onClick={handleMenuItemClick}
                                  >
                                    Dues
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
          )
        }
        else if(service?.services_id == 11)
        {
          return(
            <div className="navigation-dropdown">
            <div
              className="dropdown-trigger d-flex align-items-center"
              onMouseEnter={() => {
                setIsDropdownOpenKhidmat(true);
                setIsDropdownOpenRfWelfare(false);
              }}
              onMouseLeave={() => setIsDropdownOpenKhidmat(false)}
            >
              <Link
                to={service?.service_slug_url}
                onClick={handleMenuItemClick}
              >
                <span
                  className="nav-links font-play"
                  style={{ padding: "0px 0px" }}
                >
                  {service?.service_name}
                </span>
              </Link>
            </div>
            <div
              className={`dropdown-content ${
                isDropdownOpenKhidmat ? "open" : "d-none"
              }`}
              onMouseEnter={() => setIsDropdownOpenKhidmat(true)}
              onMouseLeave={() => setIsDropdownOpenKhidmat(false)}
              style={{ left: "0px", top: "37px",overflowY:"auto",height:"380px",overflowX:"hidden" }}
              // style={{ left: "170px", top: "0px",overflowY:"auto",height:"380px",overflowX:"hidden" }}
            >
              <div className="d-flex align-items-center gap-5">
                <div>
                  {[
                    {
                      title:"Welfare",
                      link:"#"
                    },
                    {
                      title:"Disaster Aid",
                      link:"/services/disaster-aid"
                    },
                    {
                      title:"Food",
                      link:"/services/food"
                    },
                    {
                      title:"Education",
                      link:"#"
                    },
                    {
                      title:"Health",
                      link:"/services/health"
                    },
                    {
                      title:"Utility Bills & House Rent",
                      link:"/services/utility-bills"
                    },
                    {
                      title:"Wedding",
                      link:"/services/wedding"
                    },
                    {
                      title:"House Construction",
                      link:"/services/house-construction"
                    },
                    {
                      title:"Business Startups",
                      link:"/services/business-startup"
                    },
                    {
                      title:"Ehsaas",
                      link:"#"
                    }
                  ].map((item) => (
                    <div
                      key={item.title}
                      className="dropdown-item-container"
                      onMouseEnter={() => {
                        switch (item.title) {
                          case "Welfare":
                            setIsDropdownOpenRfWelfare(true);
                            break;
                          case "Disaster Aid":
                            setIsDropdownOpenRfDisasterAid(true);
                            break;
                          case "Food":
                            setIsDropdownOpenRfFood(true);
                            break;
                          case "Education":
                            setIsDropdownOpenRfEducation(true);
                            break;
                          case "Health":
                            setIsDropdownOpenRfHealth(true);
                            break;
                          case "Ehsaas":
                            setIsDropdownOpenRfEhsaas(true);
                            break;
                          default:
                            break;
                        }
                      }}
                      onMouseLeave={() => {
                        switch (item.title) {
                          case "Welfare":
                            setIsDropdownOpenRfWelfare(false);
                            break;
                          case "Disaster Aid":
                            setIsDropdownOpenRfDisasterAid(false);
                            break;
                          case "Food":
                            setIsDropdownOpenRfFood(false);
                            break;
                          case "Education":
                            setIsDropdownOpenRfEducation(false);
                            break;
                          case "Health":
                            setIsDropdownOpenRfHealth(false);
                            break;
                          case "Business Startups":
                            setIsDropdownOpenBusiness(false);
                            break;
                          case "Ehsaas":
                            setIsDropdownOpenRfEhsaas(false);
                            break;
                          default:
                            break;
                        }
                      }}
                    >
                      <Link
                        to={`${item.link}`}
                        className="nav-links font-play dropdown-item text-start"
                        // onClick={handleMenuItemClick}
                      >
                        <p className="itemsof-dropdown mb-0">
                          {item.title.charAt(0).toUpperCase() +
                            item.title.slice(1)}
                        </p>
                      </Link>
                      {/* Conditional rendering for submenus */}
                      {item.title === "Welfare" && (
                        <div
                          className={`dropdown-content ${
                            isDropdownOpenRfWelfare
                              ? "open"
                              : "d-none"
                          }`}
                          // style={{ left: "120px", top: "0px" }}
                          style={{ left: "0px", top: "0px",position:"relative",boxShadow:"none",paddingLeft:"15px",paddingTop:"0px" }}
                        >
                          {/* Sub-items for Rf Welfare */}
                          <Link
                            to="/services/guardian"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Guardian
                            </p>
                          </Link>
                          <Link
                            to="/services/financial-aid"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Financial Aid
                            </p>
                          </Link>
                          <Link
                            to="/services/plantation"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Plantation
                            </p>
                          </Link>
                          <Link
                            to="/services/masjid-madarsa-construction"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Masjid and madrsa Construction
                            </p>
                          </Link>
                        </div>
                      )}
                      {item.title === "Disaster Aid" && (
                        <div
                          className={`dropdown-content ${
                            isDropdownOpenRfDisasterAid
                              ? "open"
                              : "d-none"
                          }`}
                          // style={{ left: "120px", top: "45px" }}
                          style={{ left: "0px", top: "0px",position:"relative",boxShadow:"none",paddingLeft:"15px",paddingTop:"0px" }}
                        >
                          {/* Sub-items for Rf Welfare */}
                          <Link
                            to="/services/corona-relief"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Corona Relief Food Pacakge
                            </p>
                          </Link>
                          <Link
                            to="/services/tharparkar"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Thar Parker Food Drive
                            </p>
                          </Link>
                          <Link
                            to="/services/balochistan"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Balochistan Flood
                            </p>
                          </Link>
                          <Link
                            to="/services/sindh-flood"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Sindh Flood
                            </p>
                          </Link>
                          <Link
                            to="/services/palestine"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Palestine
                            </p>
                          </Link>
                          <Link
                            to="/services/earthquake-food-drive"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Earthquake Food Drive
                            </p>
                          </Link>
                          <Link
                            to="/services/mastung-bomb-attack"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Mastong bomb attack
                            </p>
                          </Link>
                        </div>
                      )}
                      {item.title === "Food" && (
                        <div
                          className={`dropdown-content ${
                            isDropdownOpenRfFood ? "open" : "d-none"
                          }`}
                          // style={{ left: "120px", top: "75px" }}
                          style={{ left: "0px", top: "0px",position:"relative",boxShadow:"none",paddingLeft:"15px",paddingTop:"0px" }}
                        >
                          {/* Sub-items for Rf Welfare */}
                          {/* <Link
                            to="#"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Ehsas Program
                            </p>
                          </Link> */}
                          <Link
                            to="/services/dastarkhowan-tharparker"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Dastarkhan thar Parker
                            </p>
                          </Link>
                          <Link
                            to="/services/rashan-distribute"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Rashan distribute
                            </p>
                          </Link>
                          <Link
                            to="/services/family-support"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Family Support
                            </p>
                          </Link>
                          <Link
                            to="/services/ramzan-rashan-drive"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Ramzan Rashan Drive
                            </p>
                          </Link>
                        </div>
                      )}
                      {item.title === "Education" && (
                        <div
                          className={`dropdown-content ${
                            isDropdownOpenRfEducation
                              ? "open"
                              : "d-none"
                          }`}
                          // style={{ left: "120px", top: "120px" }}
                          style={{ left: "0px", top: "0px",position:"relative",boxShadow:"none",paddingLeft:"15px",paddingTop:"0px" }}
                        >
                          {/* Sub-items for Rf Welfare */}
                          <Link
                            to="/services/children-orientation-system"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              COS ( Children Orientation System)
                            </p>
                          </Link>
                          <Link
                            to="/services/islamic-history"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Islamic History
                            </p>
                          </Link>
                          <Link
                            to="/services/education-in-quran"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Educated in Quran
                            </p>
                          </Link>
                        </div>
                      )}
                      {item.title === "Health" && (
                        <div
                          className={`dropdown-content ${
                            isDropdownOpenRfHealth
                              ? "open"
                              : "d-none"
                          }`}
                          // style={{ left: "120px", top: "148px" }}
                          style={{ left: "0px", top: "0px",position:"relative",boxShadow:"none",paddingLeft:"15px",paddingTop:"0px" }}
                        >
                          {/* Sub-items for Rf Welfare */}
                          <Link
                            to="/services/medicine"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Medicine{" "}
                            </p>
                          </Link>
                          <Link
                            to="/services/opd"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              OPD
                            </p>
                          </Link>
                          <Link
                            to="/services/consultation-physician"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Consultations physician
                            </p>
                          </Link>
                          <Link
                            to="/services/consultation-specialist"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Consultation Specialist
                            </p>
                          </Link>
                          <Link
                            to="/services/medical-aids"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Medical Aids
                            </p>
                          </Link>
                        </div>
                      )}
                      {item.title === "Business Startups" && (
                        <div
                          className={`dropdown-content ${
                            isDropdownOpenBusiness
                              ? "open"
                              : "d-none"
                          }`}
                          // style={{ left: "120px", top: "148px" }}
                          style={{ left: "0px", top: "0px",position:"relative",boxShadow:"none",paddingLeft:"15px",paddingTop:"0px" }}
                        >
                          {/* Sub-items for Rf Welfare */}
                          <Link
                            to="/services/medicine"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Medicine{" "}
                            </p>
                          </Link>
                          <Link
                            to="/services/opd"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              OPD
                            </p>
                          </Link>
                          <Link
                            to="/services/consultation-physician"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Consultations physician
                            </p>
                          </Link>
                          <Link
                            to="/services/consultation-specialist"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Consultation Specialist
                            </p>
                          </Link>
                          <Link
                            to="/services/medical-aids"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Medical Aids
                            </p>
                          </Link>
                        </div>
                      )}
                      {item.title === "Ehsaas" && (
                        <div
                          className={`dropdown-content ${
                            isDropdownOpenRfEhsaas
                              ? "open"
                              : "d-none"
                          }`}
                          // style={{ left: "120px", top: "300px" }}
                          style={{ left: "0px", top: "0px",position:"relative",boxShadow:"none",paddingLeft:"15px",paddingTop:"0px" }}
                        >
                          {/* Sub-items for Rf Welfare */}
                          <Link
                            to="/services/khana-delhi"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Khana Delhi’s ( Food on door step){" "}
                            </p>
                          </Link>
                          <Link
                            to="/services/langur-khana"
                            className="nav-links font-play dropdown-item text-start"
                            onClick={handleMenuItemClick}
                          >
                            <p className="itemsof-dropdown mb-0">
                              Langur khana -Thar Parker
                            </p>
                          </Link>
                        </div>
                      )}
                      {/* Similar logic for other items */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          )
        }
        return(
          <Link
          to={service?.service_slug_url}
          className="nav-links font-play dropdown-item text-start"
          onClick={handleMenuItemClick}
          onMouseEnter={() => {
            setIsDropdownOpenSubServices(false);
            setIsDropdownOpenZakatServices(false);
            setIsDropdownOpenNafilServices(false);
            setIsDropdownOpenFitrahServices(false);
          }}
          key={service?.services_id}
        >
          <p className="itemsof-dropdown mb-0" style={{textTransform:"capitalize"}}>
            {service?.service_name}
          </p>
        </Link>
        )
      })
  }
</>
:
<Spinner/>
}

                      {/* <div className="navigation-dropdown">
                        <div
                          className=""
                          onMouseEnter={() =>
                            handleTheMouseEnterThree("education")
                          }
                          onMouseLeave={handleTheMouseLeave}
                        >
                          <Link
                            to="/services/education"
                            onClick={handleMenuItemClick}
                          >
                            <span
                              className="nav-links font-play"
                              style={{ padding: "0px 0px" }}
                            >
                              Education
                            </span>
                          </Link>
                        </div>
                        <div
                          className={`dropdown-content ${
                            isDropdownOpenTheDeskThree ? "open" : "d-none"
                          }`}
                          onMouseEnter={() =>
                            handleTheMouseEnterThree("education")
                          }
                          onMouseLeave={handleTheMouseLeave}
                          style={{ left: "170px", top: "0px" }}
                        >
                          <div className="d-flex align-item-center gap-1 flex-column">
                            <div className="navigation-dropdown">
                              <div
                                className="dropdown-trigger d-flex align-items-center"
                                onMouseEnter={() => {}}
                              >
                                <Link to="/services/school-fees">
                                  <span
                                    className="nav-links font-play"
                                    style={{
                                      padding: "12px 0px",
                                      textDecoration: "none",
                                      paddingLeft: "0px",
                                    }}
                                    onClick={handleMenuItemClick}
                                  >
                                    School Fees
                                  </span>
                                </Link>
                              </div>
                            </div>

                            <div className="navigation-dropdown">
                              <div
                                className="dropdown-trigger d-flex align-items-center"
                                onMouseEnter={() => {}}
                              >
                                <Link to="/services/dues">
                                  <span
                                    className="nav-links font-play"
                                    style={{
                                      padding: "12px 0px",
                                      textDecoration: "none",
                                      paddingLeft: "0px",
                                    }}
                                    onClick={handleMenuItemClick}
                                  >
                                    Dues
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {/* <Link
                        to='/services/education'
                        className="nav-links font-play dropdown-item text-start"
                        // onClick={() => setIsNotesSubMenuOpen(false)} 
                        onClick={handleMenuItemClick}
                        onMouseEnter={() => { setIsDropdownOpenSubServices(false) }}
                      >
                        <p className="itemsof-dropdown mb-0">Education</p>
                      </Link> */}

                      {/* Khidmat-e-Khalq Dropdown */}
                      {/* <div className="navigation-dropdown">
                        <div
                          className="dropdown-trigger d-flex align-items-center"
                          onMouseEnter={() => {
                            setIsDropdownOpenKhidmat(true);
                            setIsDropdownOpenRfWelfare(false);
                          }}
                          onMouseLeave={() => setIsDropdownOpenKhidmat(false)}
                        >
                          <Link
                            to="/services/khidmat-e-khalq"
                            onClick={handleMenuItemClick}
                          >
                            <span
                              className="nav-links font-play"
                              style={{ padding: "0px 0px" }}
                            >
                              Khidmat-e-Khalq
                            </span>
                          </Link>
                        </div>
                        <div
                          className={`dropdown-content ${
                            isDropdownOpenKhidmat ? "open" : "d-none"
                          }`}
                          onMouseEnter={() => setIsDropdownOpenKhidmat(true)}
                          onMouseLeave={() => setIsDropdownOpenKhidmat(false)}
                          style={{ left: "170px", top: "0px",overflowY:"auto",height:"380px",overflowX:"hidden" }}
                        >
                          <div className="d-flex align-items-center gap-5">
                            <div>
                              {[
                                {
                                  title:"Welfare",
                                  link:"#"
                                },
                                {
                                  title:"Disaster Aid",
                                  link:"/services/disaster-aid"
                                },
                                {
                                  title:"Food",
                                  link:"/services/food"
                                },
                                {
                                  title:"Education",
                                  link:"#"
                                },
                                {
                                  title:"Health",
                                  link:"/services/health"
                                },
                                {
                                  title:"Utility Bills & House Rent",
                                  link:"/services/utility-bills"
                                },
                                {
                                  title:"Wedding",
                                  link:"/services/wedding"
                                },
                                {
                                  title:"House Construction",
                                  link:"/services/house-construction"
                                },
                                {
                                  title:"Business Startups",
                                  link:"/services/business-startup"
                                },
                                {
                                  title:"Ehsaas",
                                  link:"#"
                                }
                              ].map((item) => (
                                <div
                                  key={item.title}
                                  className="dropdown-item-container"
                                  onMouseEnter={() => {
                                    switch (item.title) {
                                      case "Welfare":
                                        setIsDropdownOpenRfWelfare(true);
                                        break;
                                      case "Disaster Aid":
                                        setIsDropdownOpenRfDisasterAid(true);
                                        break;
                                      case "Food":
                                        setIsDropdownOpenRfFood(true);
                                        break;
                                      case "Education":
                                        setIsDropdownOpenRfEducation(true);
                                        break;
                                      case "Health":
                                        setIsDropdownOpenRfHealth(true);
                                        break;
                                      case "Ehsaas":
                                        setIsDropdownOpenRfEhsaas(true);
                                        break;
                                      default:
                                        break;
                                    }
                                  }}
                                  onMouseLeave={() => {
                                    switch (item.title) {
                                      case "Welfare":
                                        setIsDropdownOpenRfWelfare(false);
                                        break;
                                      case "Disaster Aid":
                                        setIsDropdownOpenRfDisasterAid(false);
                                        break;
                                      case "Food":
                                        setIsDropdownOpenRfFood(false);
                                        break;
                                      case "Education":
                                        setIsDropdownOpenRfEducation(false);
                                        break;
                                      case "Health":
                                        setIsDropdownOpenRfHealth(false);
                                        break;
                                      case "Business Startups":
                                        setIsDropdownOpenBusiness(false);
                                        break;
                                      case "Ehsaas":
                                        setIsDropdownOpenRfEhsaas(false);
                                        break;
                                      default:
                                        break;
                                    }
                                  }}
                                >
                                  <Link
                                    to={`${item.link}`}
                                    className="nav-links font-play dropdown-item text-start"
                                   
                                  >
                                    <p className="itemsof-dropdown mb-0">
                                      {item.title.charAt(0).toUpperCase() +
                                        item.title.slice(1)}
                                    </p>
                                  </Link>
                                  {item.title === "Welfare" && (
                                    <div
                                      className={`dropdown-content ${
                                        isDropdownOpenRfWelfare
                                          ? "open"
                                          : "d-none"
                                      }`}
                                      style={{ left: "0px", top: "0px",position:"relative",boxShadow:"none",paddingLeft:"15px",paddingTop:"0px" }}
                                    >
                                      <Link
                                        to="/services/guardian"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Guardian
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/financial-aid"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Financial Aid
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/plantation"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Plantation
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/masjid-madarsa-construction"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Masjid and madrsa Construction
                                        </p>
                                      </Link>
                                    </div>
                                  )}
                                  {item.title === "Disaster Aid" && (
                                    <div
                                      className={`dropdown-content ${
                                        isDropdownOpenRfDisasterAid
                                          ? "open"
                                          : "d-none"
                                      }`}
                                      style={{ left: "0px", top: "0px",position:"relative",boxShadow:"none",paddingLeft:"15px",paddingTop:"0px" }}
                                    >
                                      <Link
                                        to="/services/corona-relief"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Corona Relief Food Pacakge
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/tharparkar"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Thar Parker Food Drive
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/balochistan"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Balochistan Flood
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/sindh-flood"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Sindh Flood
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/palestine"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Palestine
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/earthquake-food-drive"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Earthquake Food Drive
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/mastung-bomb-attack"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Mastong bomb attack
                                        </p>
                                      </Link>
                                    </div>
                                  )}
                                  {item.title === "Food" && (
                                    <div
                                      className={`dropdown-content ${
                                        isDropdownOpenRfFood ? "open" : "d-none"
                                      }`}
                                      style={{ left: "0px", top: "0px",position:"relative",boxShadow:"none",paddingLeft:"15px",paddingTop:"0px" }}
                                    >
                                      <Link
                                        to="/services/dastarkhowan-tharparker"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Dastarkhan thar Parker
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/rashan-distribute"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Rashan distribute
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/family-support"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Family Support
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/ramzan-rashan-drive"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Ramzan Rashan Drive
                                        </p>
                                      </Link>
                                    </div>
                                  )}
                                  {item.title === "Education" && (
                                    <div
                                      className={`dropdown-content ${
                                        isDropdownOpenRfEducation
                                          ? "open"
                                          : "d-none"
                                      }`}
                                      style={{ left: "0px", top: "0px",position:"relative",boxShadow:"none",paddingLeft:"15px",paddingTop:"0px" }}
                                    >
                                      
                                      <Link
                                        to="/services/children-orientation-system"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          COS ( Children Orientation System)
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/islamic-history"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Islamic History
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/education-in-quran"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Educated in Quran
                                        </p>
                                      </Link>
                                    </div>
                                  )}
                                  {item.title === "Health" && (
                                    <div
                                      className={`dropdown-content ${
                                        isDropdownOpenRfHealth
                                          ? "open"
                                          : "d-none"
                                      }`}
                                      style={{ left: "0px", top: "0px",position:"relative",boxShadow:"none",paddingLeft:"15px",paddingTop:"0px" }}
                                    >
                                      <Link
                                        to="/services/medicine"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Medicine{" "}
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/opd"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          OPD
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/consultation-physician"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Consultations physician
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/consultation-specialist"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Consultation Specialist
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/medical-aids"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Medical Aids
                                        </p>
                                      </Link>
                                    </div>
                                  )}
                                  {item.title === "Business Startups" && (
                                    <div
                                      className={`dropdown-content ${
                                        isDropdownOpenBusiness
                                          ? "open"
                                          : "d-none"
                                      }`}
                                      style={{ left: "0px", top: "0px",position:"relative",boxShadow:"none",paddingLeft:"15px",paddingTop:"0px" }}
                                    >
                                      <Link
                                        to="/services/medicine"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Medicine{" "}
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/opd"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          OPD
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/consultation-physician"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Consultations physician
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/consultation-specialist"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Consultation Specialist
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/medical-aids"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Medical Aids
                                        </p>
                                      </Link>
                                    </div>
                                  )}
                                  {item.title === "Ehsaas" && (
                                    <div
                                      className={`dropdown-content ${
                                        isDropdownOpenRfEhsaas
                                          ? "open"
                                          : "d-none"
                                      }`}
                                      style={{ left: "0px", top: "0px",position:"relative",boxShadow:"none",paddingLeft:"15px",paddingTop:"0px" }}
                                    >
                                      <Link
                                        to="/services/khana-delhi"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Khana Delhi’s ( Food on door step){" "}
                                        </p>
                                      </Link>
                                      <Link
                                        to="/services/langur-khana"
                                        className="nav-links font-play dropdown-item text-start"
                                        onClick={handleMenuItemClick}
                                      >
                                        <p className="itemsof-dropdown mb-0">
                                          Langur khana -Thar Parker
                                        </p>
                                      </Link>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {/* {[
                        "raza Library",
                        "COS",
                        "islamic Event",
                        "rims",
                        "balagh",
                        "dar-ul-iffta Fareedia",
                        "construction of Mosque",
                        "qurbani",
                        "hajj",
                      ].map((item) => (
                        <Link
                          to={`/services/${item.replace(/\s+/g, '-')}`}
                          className="nav-links font-play dropdown-item text-start"
                          onClick={handleMenuItemClick}
                          onMouseEnter={() => {
                            setIsDropdownOpenSubServices(false);
                            setIsDropdownOpenZakatServices(false);
                            setIsDropdownOpenNafilServices(false);
                            setIsDropdownOpenFitrahServices(false);
                          }}
                          key={item}
                        >
                          <p className="itemsof-dropdown mb-0">
                            {item.charAt(0).toUpperCase() + item.slice(1)}
                          </p>
                        </Link>
                      ))} */}
                      
                    </div>
                  </div>
                </div>

                {/* <NavLink
                  onMouseEnter={mouseLeaveHandler}
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.activeLink} ${styles.link}`
                      : styles.link
                  }
                  to="/blogs"
                >
                  Blogs
                </NavLink> */}

                <div className="navigation-dropdown">
                  <div
                    className="dropdown-trigger d-flex align-items-center"
                    onMouseEnter={() => handleTheMouseEnter("notes")}
                    onMouseLeave={handleTheMouseLeave}
                  >
                    <span className="nav-links font-play">Insights</span>
                  </div>
                  <div
                    className={`dropdown-content ${
                      isDropdownOpenTheDesk ? "open" : "d-none"
                    }`}
                    onMouseEnter={() => handleTheMouseEnter("notes")}
                    onMouseLeave={handleTheMouseLeave}
                  >
                    <div className="d-flex align-item-center gap-1 flex-column">
                      <div className="navigation-dropdown">
                        <div
                          className="dropdown-trigger d-flex align-items-center"
                          onMouseEnter={() => {}}
                        >
                          <Link to="/videos">
                            <span
                              className="nav-links font-play"
                              style={{
                                padding: "12px 0px",
                                textDecoration: "none",
                                paddingLeft: "0px",
                              }}
                              onClick={handleMenuItemClick}
                            >
                              Videos
                            </span>
                          </Link>
                        </div>
                        {/* <div className={`dropdown-content ${isDropdownOpenZakatServices ? 'd-none' : 'd-none'}`}
                          onMouseEnter={() => setIsDropdownOpenZakatServices(true)}
                          onMouseLeave={() => setIsDropdownOpenZakatServices(false)}
                          style={{ left: "120px", top: "0px" }}
                        >
                          <div className="d-flex align-items-center gap-5">
                            <div>
                              {['medical', 'utilities', 'grocery', 'education-fees', 'wedding', 'global-relief'].map((item) => (
                                <Link
                                  to={`/${item}`}
                                  className="nav-links font-play dropdown-item text-start"
                                  onClick={handleMenuItemClick}
                                  onMouseEnter={() => setIsDropdownOpenZakatServices(true)}
                                  key={item}
                                >
                                  <p className="itemsof-dropdown mb-0">{item.charAt(0).toUpperCase() + item.slice(1).replace('-', ' ')}</p>
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div> */}
                      </div>

                      <div className="navigation-dropdown">
                        <div
                          className="dropdown-trigger d-flex align-items-center"
                          onMouseEnter={() => {
                            setIsDropdownOpenNafilServices(false);
                            setIsDropdownOpenZakatServices(false);
                            setIsDropdownOpenSubServices(false);
                            setIsDropdownOpenFitrahServices(true);
                          }}
                        >
                          <Link to="/events">
                            <span
                              className="nav-links font-play"
                              style={{
                                padding: "12px 0px",
                                textDecoration: "none",
                                paddingLeft: "0px",
                              }}
                              onClick={handleMenuItemClick}
                            >
                              Events
                            </span>
                          </Link>
                        </div>
                        {/* <div className={`dropdown-content ${isDropdownOpenFitrahServices ? 'd-none' : 'd-none'}`}
                          onMouseEnter={() => setIsDropdownOpenFitrahServices(true)}
                          onMouseLeave={() => setIsDropdownOpenFitrahServices(false)}
                          style={{ left: "120px", top: "0px" }}
                        >
                          <div className="d-flex align-items-center gap-5">
                            <div>
                              {['medical', 'utilities', 'grocery', 'education-fees', 'wedding', 'global-relief'].map((item) => (
                                <Link
                                  to={`/${item}`}
                                  className="nav-links font-play dropdown-item text-start"
                                  onClick={handleMenuItemClick}
                                  onMouseEnter={() => setIsDropdownOpenFitrahServices(true)}
                                  key={item}
                                >
                                  <p className="itemsof-dropdown mb-0">{item.charAt(0).toUpperCase() + item.slice(1).replace('-', ' ')}</p>
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div> */}
                      </div>

                      <div className="navigation-dropdown">
                        <div
                          className="dropdown-trigger d-flex align-items-center"
                          onMouseEnter={() => {
                            setIsDropdownOpenNafilServices(false);
                            setIsDropdownOpenZakatServices(false);
                            setIsDropdownOpenSubServices(false);
                            setIsDropdownOpenFitrahServices(true);
                          }}
                        >
                          <Link to="/blogs">
                            <span
                              className="nav-links font-play"
                              style={{
                                padding: "12px 0px",
                                textDecoration: "none",
                                paddingLeft: "0px",
                              }}
                              onClick={handleMenuItemClick}
                            >
                              Blogs
                            </span>
                          </Link>
                        </div>
                        
                      </div>
                      <div className="navigation-dropdown">
                        <div
                          className="dropdown-trigger d-flex align-items-center"
                          onMouseEnter={() => {
                            setIsDropdownOpenNafilServices(false);
                            setIsDropdownOpenZakatServices(false);
                            setIsDropdownOpenSubServices(false);
                            setIsDropdownOpenFitrahServices(true);
                          }}
                        >
                          <Link to="/ask-a-mufti">
                            <span
                              className="nav-links font-play"
                              style={{
                                padding: "12px 0px",
                                textDecoration: "none",
                                paddingLeft: "0px",
                              }}
                              onClick={handleMenuItemClick}
                            >
                              Ask a Mufti
                            </span>
                          </Link>
                        </div>
                        
                      </div>

                      {/* <div className="navigation-dropdown">
                        <div className="dropdown-trigger d-flex align-items-center"
                          onMouseEnter={() => {
                            setIsDropdownOpenNafilServices(true); setIsDropdownOpenZakatServices(false);
                            setIsDropdownOpenSubServices(false);
                            setIsDropdownOpenFitrahServices(false)

                          }}
                        >
                          <Link to="/nafil"><span className="nav-links font-play" style={{ padding: "12px 16px", textDecoration: "none", paddingLeft: "0px" }}
                            onClick={handleMenuItemClick}>Nafil</span></Link>
                        </div>
                        <div className={`dropdown-content ${isDropdownOpenNafilServices ? 'd-none' : 'd-none'}`}
                          onMouseEnter={() => setIsDropdownOpenNafilServices(true)}
                          onMouseLeave={() => setIsDropdownOpenNafilServices(false)}
                          style={{ left: "170px", top: "0px" }}
                        >
                          <div className="d-flex align-items-center gap-5">
                            <div>
                              {['Sadaqa', 'Rashan', 'Medical', 'Education', 'Utilities', 'Global Relief', 'construction-of-mosque', 'Qurbani', 'Box', 'Mehfil', 'Sadat', 'trust-expenses', 'Others'].map((item) => (
                                <Link
                                  to={`/${item}`}
                                  className="nav-links font-play dropdown-item text-start"
                                  onClick={handleMenuItemClick}
                                  onMouseEnter={() => setIsDropdownOpenNafilServices(true)}
                                  key={item}
                                >
                                  <p className="itemsof-dropdown mb-0">{item.charAt(0).toUpperCase() + item.slice(1)}</p>
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {/* {['hajj', 'qurbani', 'education', 'global-relief', 'construction-of-mosque', 'mehfil'].map((item) => (
                        <Link
                          to={`/${item}`}
                          className="nav-links font-play dropdown-item text-start"
                          onClick={handleMenuItemClick}
                          onMouseEnter={() => {
                            setIsDropdownOpenSubServices(false)
                            setIsDropdownOpenZakatServices(false)
                            setIsDropdownOpenNafilServices(false)
                            setIsDropdownOpenFitrahServices(false)
                          }}
                          key={item}
                        >
                          <p className="itemsof-dropdown mb-0">{item.charAt(0).toUpperCase() + item.slice(1)}</p>
                        </Link>
                      ))} */}
                    </div>
                  </div>
                </div>

                <NavLink
                  onMouseEnter={mouseLeaveHandler}
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.activeLink} ${styles.link}`
                      : styles.link
                  }
                  to="/about"
                >
                  About
                </NavLink>
                <NavLink
                  onMouseEnter={mouseLeaveHandler}
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.activeLink} ${styles.link}`
                      : styles.link
                  }
                  to="/contact"
                >
                  Contact
                </NavLink>
                <div className="navigation-dropdown">
                  <div
                    className="dropdown-trigger d-flex align-items-center"
                    onMouseEnter={() => handleTheMouseEnterTwo("notes")}
                    onMouseLeave={handleTheMouseLeaveTwo}
                  >
                    <span
                      className="nav-links font-play"
                      style={{ color: "#A5CD37" }}
                    >
                      Type of Donation
                    </span>
                  </div>
                  <div
                    style={{ left: "-0%", minWidth: "50px" }}
                    className={`dropdown-content ${
                      isDropdownOpenTheDeskTwo ? "open" : "d-none"
                    }`}
                    onMouseEnter={() => handleTheMouseEnterTwo("notes")}
                    onMouseLeave={handleTheMouseLeaveTwo}
                  >
                    <div className="d-flex align-item-center gap-1 flex-column">
                      <div className="navigation-dropdown">
                        <div
                          className="dropdown-trigger d-flex align-items-center"
                          onMouseEnter={() => {}}
                        >
                          <Link to="/services/zakat-fitrah">
                            <span
                              className="nav-links font-play"
                              style={{
                                padding: "12px 0px",
                                textDecoration: "none",
                                paddingLeft: "0px",
                              }}
                              onClick={handleMenuItemClick}
                            >
                              Zakat/Fitrah
                            </span>
                          </Link>
                        </div>
                      </div>

                      {/* <div className="navigation-dropdown">
                        <div className="dropdown-trigger d-flex align-items-center"
                          onMouseEnter={() => {
                            setIsDropdownOpenNafilServices(false); setIsDropdownOpenZakatServices(false);
                            setIsDropdownOpenSubServices(false);
                            setIsDropdownOpenFitrahServices(true)

                          }}
                        >
                          <Link to="/services/zakat-fitrah"><span className="nav-links font-play" style={{ padding: "12px 16px", textDecoration: "none", paddingLeft: "0px" }}
                            onClick={handleMenuItemClick}>Fitrah</span></Link>
                        </div>
                      </div> */}

                      <div className="navigation-dropdown">
                        <div
                          className="dropdown-trigger d-flex align-items-center"
                          onMouseEnter={() => {
                            setIsDropdownOpenNafilServices(true);
                            setIsDropdownOpenZakatServices(false);
                            setIsDropdownOpenSubServices(false);
                            setIsDropdownOpenFitrahServices(false);
                          }}
                        >
                          <Link to="/services/nafil-sadaqa">
                            <span
                              className="nav-links font-play"
                              style={{
                                padding: "12px 0px",
                                textDecoration: "none",
                                paddingLeft: "0px",
                              }}
                              onClick={handleMenuItemClick}
                            >
                              Nafil/Sadaqa
                            </span>
                          </Link>
                        </div>
                      </div>

                      {/* <div className="navigation-dropdown">
                        <div className="dropdown-trigger d-flex align-items-center"
                          onMouseEnter={() => {
                            setIsDropdownOpenNafilServices(true); setIsDropdownOpenZakatServices(false);
                            setIsDropdownOpenSubServices(false);
                            setIsDropdownOpenFitrahServices(false)

                          }}
                        >
                          <Link to="/nafil"><span className="nav-links font-play" style={{ padding: "12px 16px", textDecoration: "none", paddingLeft: "0px" }}
                            onClick={handleMenuItemClick}>Global-Relief</span></Link>
                        </div>

                      </div> */}

                      {/* <div className="navigation-dropdown">
                        <div className="dropdown-trigger d-flex align-items-center"
                          onMouseEnter={() => {
                            setIsDropdownOpenNafilServices(true); setIsDropdownOpenZakatServices(false);
                            setIsDropdownOpenSubServices(false);
                            setIsDropdownOpenFitrahServices(false)

                          }}
                        >
                          <Link to="/nafil"><span className="nav-links font-play" style={{ padding: "12px 16px", textDecoration: "none", paddingLeft: "0px" }}
                            onClick={handleMenuItemClick}>Sadaqa</span></Link>
                        </div>

                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex pe-4 align-items-center">
                {userLoginStatus?.token ? (
                  <>
                    <div className="d-flex align-items-center gap-1">
                      <p className="title mb-0 user" style={{ color: "white" }}>
                        {userLoginStatus?.full_name}
                      </p>

                      <div
                        className="navigation-dropdown"
                        ref={dropdownRef}
                        onMouseEnter={() => {
                          setOpenPortalDropdown(true);
                        }}
                      >
                        <div
                          className="dropdown-trigger d-flex align-items-center"
                          onMouseOver={() => {
                            setChangeArrowColor(true);
                          }}
                          onMouseOut={() => {
                            setChangeArrowColor(false);
                          }}
                        >
                          <img
                            alt="user icon"
                            className="menu-icon"
                            src="/images/icons/profile-icon.svg"
                          />
                          <img
                            src={`/images/icons/icon-arrow.svg`}
                            className={
                              OpenPortalDropdown
                                ? "rotate-icon arrow-icon"
                                : "rotate-back arrow-icon"
                            }
                            style={{ marginRight: "8px" }}
                          />
                        </div>
                        <div
                          className={`dropdown-content dropdown-content-profile ${
                            OpenPortalDropdown ? "open" : "none-delay"
                          }`}
                          onMouseEnter={() => {
                            setOpenPortalDropdown(true);
                          }}
                          onMouseLeave={() => {
                            setOpenPortalDropdown(false);
                          }}
                        >
                          <div
                            className="nav-links font-Open-Sans p-2"
                            onClick={() => {
                              navigate("/portal");
                            }}
                          >
                            <p
                              className="mb-0"
                              style={{ textTransform: "uppercase" }}
                            >
                              Portal
                            </p>
                          </div>
                          <div
                            className="nav-links font-Open-Sans p-2"
                            onClick={() => {
                              setOpenPortalDropdown(false);
                              logoutHandler();
                            }}
                          >
                            <p
                              className="mb-0"
                              style={{ textTransform: "uppercase" }}
                            >
                              Logout
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className={styles.buttonCont}>
                    <img
                      onClick={openSignup}
                      style={{ cursor: "pointer" }}
                      src="/images/icons/header-signup-icon.svg"
                      alt="icon"
                    />

                    <img
                      onClick={openLogin}
                      style={{ cursor: "pointer" }}
                      src="/images/icons/header-login-icon.svg"
                      alt="icon"
                    />

                    {landingPage && (
                      <a onMouseEnter={mouseLeaveHandler}>
                        <button className={styles.button1}>Chat With Us</button>
                      </a>
                    )}

                    <Modal status={isModalOpen} closeModal={closeModal}>
                      {loginPressed ? (
                        <LoginSignup
                          closeModal={closeModal}
                          openSignup={openSignup}
                        />
                      ) : (
                        <Signup closeModal={closeModal} openLogin={openLogin} />
                      )}
                    </Modal>
                  </div>
                )}
                <Link onMouseEnter={mouseLeaveHandler} to="/donations">
                  <button className="connect-button pl-5">Donate Now</button>
                </Link>
              </div>
            </div>
          </Container>
        </header>
      </>

      <div className={styles.mobileHeader}>
        <header
          ref={navbarRef}
          style={{
            width: "100%",
            position: noLinearBackground ? "absolute" : "sticky",
            zIndex: 99,
            backgroundImage:
              noLinearBackground || noLinearBackgroundVideo
                ? "none"
                : "linear-gradient(to right, #1b1b1b , rgb(69, 69, 69))",
            transition: "top 0.3s",
            top: isNavbarVisible ? "22px" : "-100px",
          }}
          className="header_cs_1"
        >
          <div className="container menu_cs_1 px-sm-3 px-4  d-flex justify-content-space-between align-content-center ">
            <h1
              className="img_h1_cs1 text-center"
              onClick={() => navigate("/")}
            >
              <img
                src="/images/icons/Raza-Foundation-Logo.png"
                className="img-fluid"
                alt=""
              />
            </h1>

            <div
              className="bars_cs1"
              onClick={() => hamburgerdropdown(selected5)}
            >
              <img
                src={`${
                  selected5
                    ? `/images/icons/cross-icon.png`
                    : `/images/icons/hamburger-icon.png`
                }`}
                className="bars"
              />
            </div>
          </div>
        </header>
        <Mobilenav
          linear={noLinearBackground}
          landingPage={landingPage}
          transfer={selected5}
          hamburgerdropdown={hamburgerdropdown}
        ></Mobilenav>
      </div>
    </>
  );
};
export default Navbar;
